.icon {
  position: relative;
}
.icon-header img {
  width: 16px;
}
.icon.noborder {
  border: none;
}
.icon.noborder img {
  border: 0px none;
}
.item.selected .icon::after {
  position: absolute;
  content: "";
  top: 0;
  left: 30px;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  background-color: #0065e8;
  border-radius: 20px;
  background-image: url(../../images/icon-smallcheck.png);
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
}
.icon img {
  image-rendering: crisp-edges;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid #e0e6ef;
}

.icon-wrapper {
  color: var(--mid-cooler-gray);
  overflow: visible;
  width: 40px;
  height: 40px;
  border: 1px solid #e0e6ef;
  border-radius: 50px;
  background-color: #f5f9fe;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
}
.icon-wrapper img,
.icon-wrapper svg {
  width: 20px;
  height: 20px;
}
