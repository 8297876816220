.sidebar {
    z-index:50;
    min-width:320px;
    min-height:100vh;
    top:0;
    right:0;
    background-color:#fff;
    box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
    transition:0.3s ease transform;
    padding: 24px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.sidebar .info{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-between;
    padding-top:20px;
    margin-bottom:40px;
    min-height:105px;
}
.sidebar .info .icon {
    margin-bottom: 8px
}
.sidebar .card {
    margin: 10px 0px 0px 0px
}

.sidebar .settings {
    position: absolute;
    top: 20px;
    right: 20px;
}


.sidebar .label {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: rgba(18, 42, 91, 0.63);
}
.sidebar .title {
    line-height:40px;
    margin-bottom: 8px;
}