.t-table_wrapper {
  position: relative;
  background-color: inherit;
  width: 100%;
  min-height: 100px;
}

.t-table-wrapper .t-loading .MuiCircularProgress-root {
  color: var(blue);
}

.t-table_overlay-loading .t-loading {
  width: 30px;
}
.t-table_overlay-loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.t-table_no-bookings {
  position: absolute;
  height: 100%;
  padding-top: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  z-index: 2;
}
.t-table_no-bookings span {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.195em;
  text-transform: uppercase;
  color: #8493ad;
}
