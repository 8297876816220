.tasks-view {
  width: 100%;
  background-color: white;
}

.task-card {
  margin-bottom: 40px;
}

.tasks-view .trip-detail-container {
  position: fixed;
}
.tasks-view .sidebar-with-page {
  padding-left: 0px !important;
}
.tasks-view .sidebar {
  padding-top: 0px !important;
  padding-left: 0px !important;
}
.tasks-view .mobi-tui-pagewrap {
  padding-top: 0px !important;
}

.map-container {
  height: 500px;
  width: 100%;
}