.tui-toggle.MuiToggleButtonGroup-root {
    color:var(--blue);
    border-color:var(--blue);
    font-family: 'tuibold';
    height:48px
}
.tui-toggle .MuiToggleButton-root {
    color:var(--blue);
    border-color:var(--blue);
    font-family: 'tuibold';
    line-height:16px;
    padding-top:10px;
    padding-bottom:9px
}

.tui-toggle .MuiToggleButton-root.Mui-selected {
    background-color: var(--blue);
    color: white;
}