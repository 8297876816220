.mobi-tui-topnav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 65px;
  z-index: 100;
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  z-index:1500
}

.mobi-tui-topnav > div {
  border-right: 1px solid #f1f1f1;
}
.mobi-tui-topnav .logo {
  width: 200px;
  height: 100%;
  margin-right: auto;
  border-right: none;
  background-image: url(../../images/img-logoheader.png);
  background-size: 130px;
  background-position: center;
  background-repeat: no-repeat;
}

.top-nav-user-container {
  height: 65px;
  display: flex;
  position: relative;
  align-items: center;
}

.top-nav-notif-container{
  height: 65px;
  display: flex;
  position: relative;
  align-items: center;
  border-left: 1px solid #f1f1f1;
}
.top-nav-user-container__dropdown {
  margin: 0;
  padding: 0;
  width: 150px;
  list-style: none;
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
  border-radius: 5px;
}

.top-nav-user-container__dropdown > li {
  background: #fff;
  width: 100%;
}

.top-nav-user-container__dropdown > li > button {
  appearance: none;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
  padding: 5px;
  text-align: left;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;

  color: #122a5b;
}

.top-nav-user-container__dropdown > li > button:hover {
  background: #f1f1f1;
}
