.mobi-tui-rqjobstatus-selector {
  --background-color: #fff;
  --text-color: #2a2c37;
  --carret-color: var(--blue);
  position: relative;
}

.mobi-tui-rqjobstatus-selector__carret {
  border-top: 0.5em solid var(--carret-color);
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  display: inline-block;
  position: relative;
  right: 0;
  top: 2px;
  pointer-events: none;
  z-index: 1;
}

.mobi-tui-rqjobstatus-selector__btn-content {
    display: flex;
    align-items: center;
}

.mobi-tui-rqjobstatus-selector__btn-content > * {
  white-space: nowrap;
}

.mobi-tui-rqjobstatus-selector__btn-content > * + *{
    margin-left: 10px;
}

.mobi-tui-rqjobstatus-selector__location-prefix {
    font-weight: 300;
}

.mobi-tui-rqjobstatus-selector__select-css {
  display: inline-block;
  font-size: 1em;
  padding: 0;
  flex: 1;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  background: transparent;
  font-weight: bold;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 0;
}

.mobi-tui-rqjobstatus-selector__select-css option {
  font-size: 12px
}
.select-css::-ms-expand {
  display: none;
}
