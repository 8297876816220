.card {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 80px;
}
.t-card .item {
  border-top: 0px;
}

.card-outline {
  background-color: transparent;
  box-shadow: none;
  border: 1px solid var(--lighter-gray);
}

.card-actions {
  width: 100%;
  align-self: flex-end;
}
.card-actions .mobi-tui-button {
  border-radius: 0;
}

.card_scrollable {
  overflow-y: scroll;
}
