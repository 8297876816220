.list-status {
  color: #d9935c;
  border: 1px solid #d9935c;
  border-radius: 36px;
  font-weight: bold;
  letter-spacing: -0.5px;
  font-size: 13px;
  margin: 0 5px;
  height: 26px !important;
  line-height: 24px !important;
  padding: 0 11px;
}
.gray {
  color: var(--status-gray);
  border-color: var(--status-gray);
}
.yellow {
  border-color: var(--status-yellow);
  color: var(--status-yellow);
}
.green {
  border-color: var(--status-green);
  color: var(--status-green);
}
