.t-card .item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.t-card .item .content {
  padding-left: 20px;
}

.t-card .item {
  border-top: 1px solid #f1f1f1;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  position: relative;
}
.t-card .item.selected {
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
}
.t-card .item:first-child {
  border-top: 0px;
}
.t-card .item.witharrow::after {
  position: absolute;
  content: "";
  top: 50%;
  margin-top: -9px;
  right: 20px;
  width: 18px;
  height: 18px;
  background-image: url(../../../images/icon-listitemarrow.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.t-card .item.witharrow.down::after {
  transform: rotate(90deg);
}
.t-card .item .icon {
  width: 65px;
  position: relative;
}

.t-card .item.selected .icon::after {
  position: absolute;
  content: "";
  top: 0;
  left: 30px;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  background-color: #0065e8;
  border-radius: 20px;
  background-image: url(../../../images/icon-smallcheck.png);
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
}
.t-card .item .icon img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid #e0e6ef;
}
.t-card .item .info {
  flex-grow: 1;
}
.t-card .item.witharrow .actions {
  padding-right: 40px;
  display: flex;
}
.t-card .item .actions {
  position: absolute;
  right: 20px;
  display: flex;
}
.item.card-content_input {
  align-items: flex-start;
}
