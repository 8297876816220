.DayPickerNavigation_button {
  border: 1px solid var(--blue);
}

.DayPicker_transitionContainer {
  border-radius: 5px;
}

.DayPicker_weekHeader_ul {
  position: relative;
  left: 5px;
}

.CalendarMonth_table {
  margin-top: 10px;
}

.CalendarDay__default {
  border: 1px solid var(--blue);
}
.CalendarDay__default:hover {
  color: var(--blue);
}

.CalendarDay__selected_span {
  background: #86c8ff;
  color: white;
  border: 1px solid var(--blue);
}

.CalendarDay__selected {
  background: var(--blue);
  color: #fff;
}

.CalendarDay__selected:hover,
.CalendarDay__selected_span:hover {
  background: var(--blue);
  border: 1px solid var(--blue);
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #86c8ff;
  border: 1px solid var(--blue);
  color: white;
}

.tooltip-container {
  z-index: 5;
}
