.nav-menu{
    background-color: white;
    z-index: 50;
    left:0;
    left:0;
    right:0;
    transition:0.3s ease all;
    padding-top:100px;
    display: flex;
    flex-direction: column;
}

.nav-menu.hide {
    /* margin-top:0 !important; */
    flex: 0 1 0
    /* transform:translateX(-100%) !important; */
}
.nav-menu.show {
    flex: 200px 0 1;
    padding-left:26px
    /* transform:translateX(0px)  !important; */
}
.nav-menu.hide a.menu-link{
    display:none
}
.menu-link {
    height: 12px;
    line-height: 11px;
    letter-spacing: 0.1em;
    border-top: 1px solid var(--lighter-gray);
    padding-top: 10px;
    padding-bottom:12px;
    cursor:pointer;
    text-decoration: none;
    margin-bottom:12px;
}

.menu-link.selected {
    border-color: var(--mid-gray)
}
.menu-link.selected a {
    color: var(--dark)
}

.menu-link a{
    color: var(--mid-gray);
    text-decoration: none;
}

.menu-link a:hover {
    color: var(--dark);
}