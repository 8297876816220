.t-input-label {
    align-self: flex-start;
    color: var(--mid-dark-gray);
    padding-bottom:8px;
    font-size: 12px;
}

.t-radio .t-input-label {
    color: var(--dark-gray);
    font-size: 14px;
}