.t-text-input {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.t-text-input .t-label {
  margin-left: 20px;
}
.t-text-input.collapse {
  height: 60px;
  transition: 0.3s ease-in-out height;
  overflow: auto;
}
.t-text-input.expand {
  height: 120px;
  transition: 0.3s ease-in-out height;
}
.t-text-input textarea {
  border-radius: 0px;
  border: none;
}
.t-text-input .button_text {
  background-color: white;
  border: none;
  outline: none;
  padding-left: 0px;
  width: min-content;
}
.t-text-input .text-display {
  box-sizing: -box;
  font-size: 16px;
  padding-top: 8px;
  padding-left: 20px;
  font-family: "tuilight";
}
