.settings {
    width:36px;
    height:36px;
    border-radius:36px;
    border:1px solid #D8D8D8;
    background-size:16px;
    background-position:center;
    background-repeat:no-repeat;
    opacity:0.7;
    transition:0.3s ease opacity;
    margin:0 5px;
}
.settings:hover{
    opacity:1;
    cursor:pointer;
}
.settings{
    background-image:url(../../images/icon-edit.png);
}