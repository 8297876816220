.alert {
  color: white;
  line-height: 18px;
  padding: 4px;
  margin-top: 4px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--red);
  align-self: flex-start;
}

.alert.outline {
  color: var(--red);
  border: 1px solid var(--red);
  background-color: transparent;
  box-sizing: border-box;
}

.alert.text-only {
  border: none;
  background-color: transparent;
  color: var(--red);
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0 0 0 0;
}
