.card .item {
  width: 100%;
}
.t-card .item.card-content-horiz {
  flex-direction: row;
}
.item-info .t-title {
  white-space: nowrap;
}
.item-info .t-label {
  white-space: nowrap;
}
