.loading-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: inherit
}
.loading-lightbox {
    padding-top: 20px;
    left:0;
    display: flex;
    justify-content: space-around;
    background-color: inherit;
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: none!important;
    z-index: inherit;
}

.tui-loading {
    z-index: inherit;
}
.tui-loading.MuiCircularProgress-colorPrimary {
    color: var(--blue);
}
