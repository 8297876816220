.header-notif-button.MuiButtonBase-root {
    padding: 20px 20px 18px 20px;
    width:60px;
    color: var(--light-gray)
}


.header-notif-button.MuiButtonBase-root .icon-header{
    margin: 6px;
}

.header-notif-button.MuiButtonBase-root .t-badge{
    padding:3px;
}