.mobi-tui-button {
    display: flex;
    font-family: 'tuibold';
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: capitalize;
    padding-top: 9px;
    padding-bottom:10px;
    border-radius: 5px;
    width:100%;
    border-style: solid;
    box-sizing: border-box;
    border-width:1px;
    height: 36px;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.default {
    background: var(--blue);
    border-color:var(--blue);
    color: var(--blue);
}
.negative {
    background: var(--red);
    border-color:var(--red);
    color: var(--red);
}

.button.red {
    background: var(--red);
    border-color:var(--red);
    color: var(--red);
}
.light {
    background:var(--light-gray);
    border-color:var(--mid-gray);
    color:var(--mid-gray)!important;

}
.outline {
    background:transparent;
}

.fill {
    border:none !important;
    color: white;
}

.full-width {
    border-radius: 0px;
    width:100%
}

.btn-group-horiz .long {
    border-radius: 0px;
    width:60%
}
.btn-group-horiz .short {
    border-radius: 0px ;
    width:40%
}

.btn-group-horiz {
    display: flex;
    flex-direction: row;
}
.btn-small {
    height: 30px
}

.btn-large {
    height: 36px
}

:disabled {
    background: var(--lightest-gray);
    border-color:var(--mid-gray);
    color: var(--mid-gray);
}