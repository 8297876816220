.b-pagebutton {
   border-radius:5px;
   padding:0 25px;
   position:relative;
   border:none
}
.b-pagebutton.editselected {
    padding-left:50px;

}
.b-pagebutton.editselected::before {
    position:absolute;
    content:'';
    top:50%;
    margin-top:-12px;
    left:16px;
    width:20px;
    height:20px;
    border-radius:20px;
    background-image:url(../../images/icon-smallcheck.png);
    background-size:10px;
    background-position:center;
    background-repeat:no-repeat;
}