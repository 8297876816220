@font-face {
  font-family: "tuibold";
  src: url("./fonts/TUITypeLt_LATN_W_Bd.eot");
  src: local("☺"), url("./fonts/TUITypeLt_LATN_W_Bd.woff") format("woff"),
    url("./fonts/TUITypeLt_LATN_W_Bd.ttf") format("truetype"),
    url("./fonts/TUITypeLt_LATN_W_Bd.svg") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "tuilight";
  src: url("./fonts/TUITypeLt_LATN_W_Rg.ttf");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:wght@400;600;700&display=swap");

body,
html {
  width: 100%;
  height: 100%;
  /* colors */
  --light-blue: #f7fbff;
  --blue: #0065e8;
  --red: #d95c5c;
  --lightest-gray: #f1f1f1;
  --lighter-gray: #eeeeee;
  --light-gray: #dfdfdf;
  --mid-gray: #949494;
  --mid-dark-gray: rgba(18, 42, 91, 0.74);
  --dark-gray: #122a5b;
  --mid-cooler-gray: #a1acc2;
  --mid-cool-gray: #a3abb6;

  --status-gray: #bdbdbd;
  --status-yellow: #d9935c;
  --status-green: #4da56b;
}
body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-size: 14px;
  color: #122a5b;
  background-color: #f7fbff;
}

.MuiInputBase-root {
  font-family: "Open Sans", sans-serif !important;
}

body * {
  box-sizing: border-box;
}

input,
button,
textarea,
select {
  font-family: "Open Sans", sans-serif;
  color: #122a5b;
  font-size: 18px;
}

input:focus,
button:focus {
  outline: 0 !important;
}

input::-webkit-input-placeholder {
  /* Edge */
  color: #c5cbd7;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c5cbd7;
}

input::placeholder {
  color: #c5cbd7;
}
/* Global styles */

.mobi-tui-topnav .search {
  border-right: 1px solid #f1f1f1;
  flex-grow: 1;
  background-image: url(./images/icon-search.png);
  background-size: 20px;
  background-position: 25px center;
  background-repeat: no-repeat;
}
.mobi-tui-topnav .search input {
  width: 100%;
  border: 0px;
  border-radius: 0px;
  padding: 20px 20px 20px 60px;
  background-color: transparent;
  font-weight: bold;
}
.mobi-tui-topnav .user {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 20px;
}
.mobi-tui-topnav .user .avatar {
  padding-right: 10px;
}
.mobi-tui-topnav .user .avatar img {
  width: 30px;
  border-radius: 30px;
}
.mobi-tui-topnav .user .menu {
  padding-right: 20px;
  position: relative;
}
.mobi-tui-topnav .user .menu::after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  margin-top: -5px;
  background-image: url(./images/icon-downarrow.png);
  width: 8px;
  height: 8px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.mobi-tui-pagewrap {
  padding: 120px;
  transition: 0.3s ease padding;
}
body.viewSidebar .mobi-tui-pagewrap {
  padding-right: 420px;
}
.mobi-tui-sidebar {
  z-index: 50;
  width: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
  transform: translateX(300px);
  transition: 0.3s ease transform;
}
body.viewSidebar .mobi-tui-sidebar {
  transform: translateX(0px);
}
.t-footer {
  font-family: "tuilight";
  font-size: 12px;
  color: var(--mid-dark-gray);
}

.t-card {
  background-color: #fff;
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
  border-radius: 5px;
  overflow: hidden;
}

.menu-app-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.menu-app-container .app-content {
  flex: 1;
}
.menu-app-container .menu-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 65px;
}

.list-action {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 1px solid #d8d8d8;
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.7;
  transition: 0.3s ease opacity;
  margin: 0 5px;
}
.list-action:hover {
  opacity: 1;
  cursor: pointer;
}
.list-action.edit {
  background-image: url(./images/icon-edit.png);
}

.trip-detail-container {
  display: block !important;
}

.viewSidebar .trip-detail-container {
  transform: translateX(100%) translateX(-320px) !important;
}
.viewTripDetail .viewSidebar .trip-detail-container {
  transform: translateX(0) !important;
}

.sidebar {
  position: absolute;
  left: 0;
  right: auto !important;
  margin-top: 0 !important;
  padding-top: 89px !important;
  box-shadow: none !important;
}
.viewSidebar .sidebar {
  box-shadow: 0px 0px 33px rgb(17 43 91 / 8%) !important;
}
.page {
  position: absolute;
  background-color: white;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 120px 60px 60px 380px;
  z-index: 10;
}

.sidebar .t-title.xs {
  font-size: 16px !important;
}
.sidebar .t-card .item .icon img {
  width: 32px;
  height: 32px;
}
.sidebar .t-card .item .icon {
  width: 42px;
  min-width: 42px;
}

.top-nav-user-container .menu::after {
  opacity: 0.3;
  transition: 0.3s ease opacity, 0.3s ease transform;
}

.top-nav-user-container:hover {
  cursor: pointer;
}

.top-nav-user-container:hover .menu::after {
  opacity: 1;
}
.top-nav-user-container.isopen .menu::after {
  opacity: 1;
  transform: rotate(180deg);
}
.top-nav-user-container .tooltip-container {
  width: 100%;
  padding: 5px;
  margin-top: 12px;
  animation: 0.3s ease tooltipUser;
  transition: 0.3s ease opacity;
}
@keyframes tooltipUser {
  from {
    opacity: 0;
    transform: translateY(65px);
  }
  to {
    opacity: 1;
    transform: translateY(55px);
  }
}

.top-nav-user-container__dropdown {
  width: 100% !important;
  overflow: hidden;
}
.top-nav-user-container__dropdown > li > button {
  padding: 12px !important;
  text-align: center !important;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #6a7998 !important;
  font-family: "tuibold";
  text-transform: uppercase;
}
.top-nav-user-container__dropdown > li > button:hover {
  color: #122a5b !important;
  background-color: #fff !important;
}

.mobi-tui-location-selector__location-prefix,
.tui-date-filter-value::before {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}
.mobi-tui-button.calendar .tui-date-filter-value::before {
  content: "Viewing ";
}
.mobi-tui-button.calendar .tui-date-filter-value svg {
  position: absolute;
  left: 15px;
}

.mobi-tui-button {
  height: 48px !important;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
.mobi-tui-button.calendar {
  padding-left: 43px;
}

.mobi-tui-filterbar .dropdown {
  border-top: 1px solid transparent;
  margin-top: -1px;
  margin-right: 0px !important;
  /*padding:0 10px;*/
  padding: 0 20px 0 0;
}
.mobi-tui-filterbar .dropdown.active {
  border-color: #8493ad;
}

.MuiChip-root {
  background-color: #d7dee8 !important;
  color: #122a5b !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 11px !important;
  position: relative;
}
.MuiChip-deleteIcon {
  border: 1px solid #fff;
  border-radius: 20px;
  color: #122a5b !important;
  margin: 0 !important;
  background-color: #fff;
  position: absolute;
  top: -5px;
  right: -5px;
}
.MuiChip-labelSmall {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.MuiChip-sizeSmall {
  margin: 2px;
  line-height: 23px;
  display: inline-block !important;
}
.chips {
  display: flex;
  flex-direction: column;
}
.chips > div {
  align-self: flex-start;
}
.MuiList-padding {
  padding: 0 !important;
}
.MuiListItem-root.MuiListItem-button {
  border-bottom: 1px solid #f1f1f1 !important;
}
.MuiListItem-gutters {
  padding-left: 5px !important;
}
.MuiPopover-root .MuiButtonBase-root .MuiSvgIcon-root {
  background-color: #dfdfdf;
  border-radius: 20px;
}
.MuiPopover-root .MuiButtonBase-root .MuiSvgIcon-root path {
  opacity: 0;
}
.MuiPopover-root .MuiListItem-root.Mui-selected .MuiSvgIcon-root {
  background-color: #fff;
}

.MuiPopover-root .MuiListItem-root.Mui-selected .MuiSvgIcon-root path {
  opacity: 1;
}

.MuiPopover-root .MuiListItem-root.Mui-selected {
  background-color: #fff !important;
}
.MuiPopover-root .MuiListItem-root.Mui-selected path {
  fill: var(--blue);
}

.MuiPopover-root .MuiTypography-body1 {
  font-family: "Open Sans", sans-serif !important;
  color: #122a5b !important;
  font-size: 13px !important;
}

.MuiPopover-root .MuiListItem-button:hover {
  background-color: #fff !important;
}

.MuiPopover-root .MuiListItem-root.Mui-selected .MuiTypography-body1 {
  font-weight: 600 !important;
}
.MuiPopover-root .MuiListItem-root::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 15px;
  width: 20px;
  height: 20px;
  background-image: url(./images/icon-filter-x.svg);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.3s ease opacity, 0.3s ease transform;
  pointer-events: none;
  transform: translateY(10px);
  opacity: 0;
}
.MuiPopover-root .MuiListItem-root.Mui-selected::after {
  transform: none;
  opacity: 1;
}

.MuiPaper-elevation8 {
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08) !important;
}

.SearchBox_search-wrapper__3kXsZ {
  border-top: 1px solid transparent;
  margin-top: -1px;
}
.SearchBox_selected__27uZR {
  border-top: 1px solid #8493ad !important;
  margin-top: -1px;
  padding-left: 10px;
}
.SearchBox_search-input__20ECW {
  color: #122a5b !important;
}

.menu-app-container {
  display: block;
}

.menu-app-container .nav-menu {
  position: fixed;
  z-index: 10;
}
.menu-app-container .menu-wrapper {
  position: absolute;
  z-index: 10;
}
.menu-app-container .nav-menu {
  width: 200px;
  height: 100%;
  left: 0;
  top: 0;
  padding: 120px 0px 0px 20px;
  transform: translateX(-200px);
}
.menu-app-container.activemenu .nav-menu {
  transform: none;
}
.menu-app-container.activemenu .app-content {
  transform: translateX(200px);
}
.menu-app-container .menu-wrapper,
.menu-app-container .app-content {
  transition: 0.3s ease transform;
}
.menu-app-container.activemenu .menu-wrapper {
  transform: translateX(200px);
}

.mobi-tui-topnav > .search {
  background-image: none;
}
.sidebar {
  max-width: 320px;
}
.sidebar .title {
  white-space: normal !important;
  text-align: center;
}
/*
*/
/* text styles */
.t-label {
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.195em;
  text-transform: uppercase;
  color: #8493ad;
}
.t-title {
  font-size: 34px;
  letter-spacing: -0.005em;
  font-family: "tuibold";
}
.t-title.lg {
  font-size: 45px;
}
.t-title.sm {
  font-size: 28px;
}
.t-title.xs {
  font-size: 20px;
}
.t-title.xxs {
  font-size: 14px;
}
.t-copy {
  font-size: 18px;
  font-family: "tuilight";
}
.t-copy.heavy {
  font-weight: 700;
}
.t-copy.md {
  font-size: 16px;
}
.t-copy.sm {
  font-size: 14px;
}
.t-copy.xs {
  font-size: 12px;
}
.t-copy.light {
  color: #a3abb6;
}
.t-copy.dark {
  color: #071d3b;
}
.t-copy.midlight {
  color: #6a7889;
}

.t-copy.red {
  color: var(--red);
  font-size: 16px;
  font-family: "tuibold";
}
.t-compact {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

/* helpers */
.t-box-shadow {
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
}
.mb-xs {
  margin-bottom: 5px;
}
.mb-s {
  margin-bottom: 10px;
}
.mb-m {
  margin-bottom: 20px;
}
.mb-l {
  margin-bottom: 40px;
}
.mb-xl {
  margin-bottom: 80px;
}

.t-center {
  text-align: center;
}
.t-right {
  text-align: right;
}
.t-left {
  text-align: left;
}

.MuiToggleButton-label {
  font-size: 12px !important;
  line-height: 14px;
}

.card-placeholder .icon {
  border: 3px dashed var(--light-gray) !important;
  border-radius: 50px;
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  margin-right: 10px;
}
.card-placeholder .icon img {
  display: none;
}
.card-placeholder .t-label {
  color: #adadad !important;
}

.mobi-tui-filterbar {
  flex-wrap: wrap;
}
.mobi-tui-filterbar > .label {
  margin-right: 30px !important;
  padding-bottom: 20px;
}
.filter-container {
  margin-left: 0 !important;
  flex-grow: 1;
  border-top: 1px solid #d9e0ea;
  margin-top: -1px;
}
.filter-container .dropdown {
  flex-grow: 1;
  margin-bottom: 10px;
}
.search-bar {
  border-top: 1px solid #d9e0ea;
  margin-left: 0px !important;
  flex-grow: 1;
  margin-top: -1px;
}
@media (max-width: 1030px) {
  .mobi-tui-pagewrap {
    padding: 150px 40px 40px;
  }
  .sidebar-with-page__new-trip .mobi-tui-pagewrap {
    padding: 150px 40px 40px 360px;
  }
  .sidebar-with-page__new-trip .viewTripDetail .sidebar {
    transform: translateX(0);
  }
}

@media (max-width: 1030px) {
  .viewTripDetail .sidebar {
    transform: translateX(-320px);
  }

  .mobi-tui-topnav .logo {
    position: absolute;
    transform: translateX(-200px);
  }
  .mobi-tui-topnav .logo {
    display: none;
  }
  .mobi-tui-pageheader {
    flex-direction: column;
  }
  .mobi-tui-pageheader .title {
    text-align: center;
  }
  .mobi-tui-pageheader .actions {
    margin: 0px auto !important;
  }
  .mobi-tui-filterbar {
    flex-direction: column !important;
  }
  .filter-container {
    flex-direction: row;
    margin-left: 0px !important;
  }
  .filter-container .dropdown {
    padding: 0 !important;
    border-top: 1px solid #f1f1f1;
    flex-grow: 1;
  }
  .mobi-tui-filterbar .chips {
    margin-bottom: 10px;
  }
  .search-bar {
    /*        border-top:1px solid #f1f1f1;*/
    margin-left: 0px !important;
  }
  .SearchBox_search-wrapper__3kXsZ {
    padding-left: 0px !important;
  }
  .MuiSelect-selectMenu,
  .MuiSelect-select.MuiSelect-select {
    font-size: 13px !important;
  }

  .list-action {
    display: none !important;
  }

  @media (max-width: 800px) {
    .filter-container {
      flex-direction: column;
    }
    .filter-container {
      display: block !important;
    }
    .filter-container .dropdown {
      width: 50%;
      float: left;
    }
    .filter-container .dropdown:nth-child(2n + 1) {
      clear: left;
    }
  }

  .item-group.item-hotel {
    margin-left: 20px;
  }

  .t-textarea {
    padding-top: 4px;
    border-radius: 5px;
    font-family: "tuilight";
    font-size: 16px;
    line-height: 18px;
    padding: 9px 13px 13px 6px;
  }
  .t-textarea.bg_blue {
    background-color: var(--light-blue);
  }
  .t-tex-area.bg_white {
    background-color: var(--white);
  }

  .t-textarea.h_one-line {
    height: 34px;
    white-space: nowrap;
  }

  .t-textarea.h_multiline {
    white-space: normal;
    height: 70px;
  }
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.loading-spinner {
  z-index: 50;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
