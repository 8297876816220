/*
Search BOX
*/
.search-input {
  -webkit-appearance: none;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
  letter-spacing: 0.02em;
  color: #8493ad;
  background: none;
  /* font-family: TUITypeLight LATN; */
}
.search-wrapper {
  display: flex;
  padding-left: 10px;
  padding-right: 0px;
}
.selected {
  border-top: 1px solid #8493ad;
}

.search-container {
  position: relative;
  display: flex;
  margin-top: 10px;
}
.btn-search {
  position: absolute;
  right: 0;
  top: 0;
}
.search-loader {
  margin-right: 10px;
  margin-top: 5px;
}

.clear-search-btn {
  cursor: pointer;
  margin-right: 10px;
  border: none;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  font-size: 11px;
  font-weight: 700;
  color: #8493ad;
}
.clear-search-btn:focus {
  outline: none;
}

.hidden {
  display: none;
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
