body {
  background-color: #f7fbff;
}
.b-menu {
  cursor: pointer;
}
.page {
  margin-right: auto;
  width: 100%;
}

.tui-date-filter-value {
  white-space: nowrap;
}

.trips-view-footer {
  background-color: white;
  display: inline-block;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}

.trips-total-cost {
  color: black;
  font-weight: 600;
  font-size: 16px;
}
