.icon-gray img{
    background-color: var(--status-gray);
    border-color: var(--status-gray);
}
.icon-green img{
    background-color: var(--status-green);
    border-color: var(--status-green);
}
.icon-yellow img{
    background-color: var(--status-yellow);
    border-color: var(--status-yellow);
}