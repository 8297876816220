@import url(https://fonts.googleapis.com/css?family=Open+Sans:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html::-webkit-scrollbar {
  width: 10px;
  background-color: #a3a4b5;
}

html::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px;
}

html::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}

@font-face {
  font-family: "tuibold";
  src: url(/static/media/TUITypeLt_LATN_W_Bd.09b39583.eot);
  src: local("☺"), url(/static/media/TUITypeLt_LATN_W_Bd.195da464.woff) format("woff"),
    url(/static/media/TUITypeLt_LATN_W_Bd.f8440d4f.ttf) format("truetype"),
    url(/static/media/TUITypeLt_LATN_W_Bd.ed727c35.svg) format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "tuilight";
  src: url(/static/media/TUITypeLt_LATN_W_Rg.e4ebb5e5.ttf);
  font-weight: normal;
  font-style: normal;
}

body,
html {
  width: 100%;
  height: 100%;
  /* colors */
  --light-blue: #f7fbff;
  --blue: #0065e8;
  --red: #d95c5c;
  --lightest-gray: #f1f1f1;
  --lighter-gray: #eeeeee;
  --light-gray: #dfdfdf;
  --mid-gray: #949494;
  --mid-dark-gray: rgba(18, 42, 91, 0.74);
  --dark-gray: #122a5b;
  --mid-cooler-gray: #a1acc2;
  --mid-cool-gray: #a3abb6;

  --status-gray: #bdbdbd;
  --status-yellow: #d9935c;
  --status-green: #4da56b;
}
body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-size: 14px;
  color: #122a5b;
  background-color: #f7fbff;
}

.MuiInputBase-root {
  font-family: "Open Sans", sans-serif !important;
}

body * {
  box-sizing: border-box;
}

input,
button,
textarea,
select {
  font-family: "Open Sans", sans-serif;
  color: #122a5b;
  font-size: 18px;
}

input:focus,
button:focus {
  outline: 0 !important;
}

input::placeholder {
  color: #c5cbd7;
}
/* Global styles */

.mobi-tui-topnav .search {
  border-right: 1px solid #f1f1f1;
  flex-grow: 1;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAS3SURBVHgB1Vkxb9tGFH53pERKTl2isWs10MAhKFTXbTV4yOBBQ39AhgZwig4a85M6eihqFV0ydOjQQUMHDR2ExnU0eODQQWgchHUQiUwoXt4TIkWO76ijSDrKN9gC73C8j+/dx/c+MlgBrtuy/eqtBgdR48DdmIENQjjzCYz5TAhfgBgyA7zJ8/HQ9x76UABYmslO49A1OG8J5AApIRgMOBf9p4+OB5AjtAhk2fgVYGRCFnVenP46hByQSIBS5aJabwHEdyB3iN7myOp63lEAGaAk4Lh3HV6ptDGfHSgKGI14PD7Kcj4M2cVr2TyBMZuZZsO6cXsQ+IOVInElArqbF7EIGId+DGwIo5HngBPM0mFj917NjkxnYgAqFZ6bZQ8iQyQuEZjmfOWzB0k3pI2jPPacwO7p5q+z+32TC9FKJIIkNsfWj2nPxKUU4rcOvkVKt9XTRe/jsdUZnv105vv9CDQRPHk0tOr7AxFFNmOsJp2E6RSWInP85OQMUmAeAQq7BaUHqolxHHf9QacLGYGS3OIoyaC+zxHexwNN8NkPS5iHCYvmsnkCrUPrqcaNBHLS+fTn5uf3G2CwfdkELAn6zwad3yFHBOcnXuWTPRs4q0uGHevmlx7O0TrQ0wjEBmtKR/FgTSyrCwVgM7S6JAiysTRR4CSbjEFDNhgz1vX7R4UUYaQ2pGayMSpZXLdtgwa4Ua7IVQGfvn/6cx8KBEmxKgp+6UVDZw2OVaIrG0B58qBgUBSYAOlD4iWzprEEHiNDrssvTci17FUBz5+0KsX0ckEDfLLYiCwuPIkKyf0rKJc92WUmmN4Z4IrXe171+jI4PshLB81CksN7RtZ+QElAV8beN3iMcikb8MG/FgLb7j2ljIMGOFPoMJRKLlwD4rIpFxFtAsA86YCmDmcFNT2y66guWiLC1ToMzes4B1zhdLwy9F6k3BmVpS8sjIzt20EBbsRbUKemkkvDsPQiMH2dK8oGxtmdoqIw7b2pzZSATDDdInIqoxNFg0FRuKiGLSgClYqyR47QwQNNTBsaah6q21+5+FOyIKtv7HwDo//+9iAnbO390MJ/8vRE9fn/n+PfQBPzF9kkoc3DFrD15qaZQevQeqpxsh0hBeauBEUhoc3DByPc6taegybUcBUTiiwbcj2EiA/Us0Tv4vSXVD3IJVtl56P9f0Mz2iOLQzobLZGpk/bp1wFZJaAB2rhZPzgY843vGEs2h7E3OB+fn6Qq4686c822w8OwreOmTdUL+4YAS+9Z9Tr9dgCODdWqS98PRAxNVDNtJSMT4enj44fa82UXtUkUhDQkpOZuMOwH5KSxKGoo06lIYKrSedNJJ0M1QCR2buz3Qx6ZqoOdDaIXY84nWI1aJLS+0GzvtmsTER7mkVIooR7+687sQ+eL+3exK2yq5i9Lp1TfyMjBe2XETZMZWpbHIt7d+CKykEhFYH5DPOTGKKwJE9w4FjWKzGJvTbU89RnoeHj0/YAKxmWt46okViJQFFYhYcAaAauBgYUHN83BXisChLQk1o4AIQ2JtSRA0CGB3xHWMwIzLCcBtbUmQFhCIlp7AgQVCXwH/PVBECAQCWq40O7ZQs8nwgbrz2ePO3+8BiIoQrq/cS96AAAAAElFTkSuQmCC);
  background-size: 20px;
  background-position: 25px center;
  background-repeat: no-repeat;
}
.mobi-tui-topnav .search input {
  width: 100%;
  border: 0px;
  border-radius: 0px;
  padding: 20px 20px 20px 60px;
  background-color: transparent;
  font-weight: bold;
}
.mobi-tui-topnav .user {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 20px;
}
.mobi-tui-topnav .user .avatar {
  padding-right: 10px;
}
.mobi-tui-topnav .user .avatar img {
  width: 30px;
  border-radius: 30px;
}
.mobi-tui-topnav .user .menu {
  padding-right: 20px;
  position: relative;
}
.mobi-tui-topnav .user .menu::after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  margin-top: -5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAXCAYAAAAV1F8QAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFYSURBVHgBvZRNTsMwEIWfQ4XErhHiACwLC1iypOfIqjeAE5DcgN4gSPwtuQG5AYgF7Y4cANGsKjb2MBMUKZjUSdqkT4rkGY/9PPIXA1uSKgbDUXDhKRqiJw0s3yv0JFUO/KPghROn6EFeOSCjLtGT/hhl89tEKSToXrFnZ7TWE/FEhzJGR/+Msvljylc3RWeiSPb0Kk+wO7hGB10RITXLpeyFSqPsNc6MoY3BIOJu0qf8wMpVuH8cPPOpzrGWCdLF7O6wiD1XsdYqwpoi0uNy7DQS3EG4QXvFv1A1NBIZ0iFagaEywdnO1hq1x91M7W4aGeVLGXe53Lo6qfl6vw+r5hoZCe6E+ndQcF4158Tblgt3zieM83jV2kYdFXLhzjhPXGt30ELfn2/p3sGJz8MzaypezB6cv0GrjkQMRogS7vl7VoHzxkYCRhl3vuRKnDuTPwo+5Gta/wNZwpgGdODnmwAAAABJRU5ErkJggg==);
  width: 8px;
  height: 8px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.mobi-tui-pagewrap {
  padding: 120px;
  transition: 0.3s ease padding;
}
body.viewSidebar .mobi-tui-pagewrap {
  padding-right: 420px;
}
.mobi-tui-sidebar {
  z-index: 50;
  width: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
  transform: translateX(300px);
  transition: 0.3s ease transform;
}
body.viewSidebar .mobi-tui-sidebar {
  transform: translateX(0px);
}
.t-footer {
  font-family: "tuilight";
  font-size: 12px;
  color: var(--mid-dark-gray);
}

.t-card {
  background-color: #fff;
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
  border-radius: 5px;
  overflow: hidden;
}

.menu-app-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.menu-app-container .app-content {
  flex: 1 1;
}
.menu-app-container .menu-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 65px;
}

.list-action {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 1px solid #d8d8d8;
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.7;
  transition: 0.3s ease opacity;
  margin: 0 5px;
}
.list-action:hover {
  opacity: 1;
  cursor: pointer;
}
.list-action.edit {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANDSURBVHgBvZjPTxNBFMffTLctNYj14tX1LxD+AsrZgxL04AlIvBjF0sRE64XlhBqTtiHEi4lw8qCmXDy7/gX4H7hePVXFQFk643tLV7fT/TG7DftJBtrXNzPf+fXm7TKYgJc73boUwpIMqvSdSegB5+0nDxY3ISMMMrK1/b7GGP8c9puUYqG5dseGDHDICAN2K6bZGmQkVtDW665ptd5Wx+ytdyYwdjOyUQZ1z0eB2qI2IYbQJaPGWLFEy2FicdCpc+T+2i0b07OcG/MgxLq/b2Ia7kmA/QEbdNyTP06lVK0H6jnSPVloNu46kCRIEXOehIoaW7KcxBD+wCFS0FbLW18T8sN89ebTVYgS1Md1p7WH/HAOf//4CVGCrMZiT0jRgZxgIDpWY7UXKYjonxptyIkj93BXtY0JKpZcE3KCwohqM1RDQRbqoAndXQLkHkjx9czA8Tph86B9MHgN/9gjbQa/UAziRukgKegRUspO/7Rg0b4L2q1WtzplDCyM5IkDowMk3JO5YCzyBNFFqRuBfTHNtdvrcT7Ptz+0dUQBBUgp9vC/TRcye7HzcUNKsEAf59jlc+rMqNBMVYriG14fiQP0wdlpcJCwDumwk8R4gtAHZ3IP0rHB04yAkGLwRdvX3+y6/qglcz50XvDUVwXjs/quhXlIAWnhAwGp8l/O2HJY0qYy9KlBCoSEDn/2aKlNOTAWEuYkVaJ1rhRnNpL8KsUq+ZhJfhRcqe+zPHzJUgJj1+RFcaCz0bFiG7PITfVypJkhMRJE4un1Iv0px8C46ATaHQWDpIVPE4kzMMTBYvsnD/fMdexkRSe4Dtl9+nBpNWgwQpxsLLqCTCwrKGTFN8gUD1aUb6u2sPQjVeyYBEr+VduYoAslWIacKBszY/tsZIK9mxo3NeSUV1PcwYNxLXgwRmaofHa6TMgJOs3TF69cCtpGBA2PnwP54Ty+d+M7RAkipMsXIB9RzrAviBVEsxQU5UdSzIEuc8bnKKXQuf+CEZjqCgEN+D9Qr49gQPxXL65RejHQP4aemv9oRPTQDunQlKeg2rw/LkRLUKzYmIiuk+JGMUk+ZEf/JPchI5kFUUJOe8R7jTeEPuPTaCPr2zPiL179eZ3/p5rgAAAAAElFTkSuQmCC);
}

.trip-detail-container {
  display: block !important;
}

.viewSidebar .trip-detail-container {
  transform: translateX(100%) translateX(-320px) !important;
}
.viewTripDetail .viewSidebar .trip-detail-container {
  transform: translateX(0) !important;
}

.sidebar {
  position: absolute;
  left: 0;
  right: auto !important;
  margin-top: 0 !important;
  padding-top: 89px !important;
  box-shadow: none !important;
}
.viewSidebar .sidebar {
  box-shadow: 0px 0px 33px rgb(17 43 91 / 8%) !important;
}
.page {
  position: absolute;
  background-color: white;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 120px 60px 60px 380px;
  z-index: 10;
}

.sidebar .t-title.xs {
  font-size: 16px !important;
}
.sidebar .t-card .item .icon img {
  width: 32px;
  height: 32px;
}
.sidebar .t-card .item .icon {
  width: 42px;
  min-width: 42px;
}

.top-nav-user-container .menu::after {
  opacity: 0.3;
  transition: 0.3s ease opacity, 0.3s ease transform;
}

.top-nav-user-container:hover {
  cursor: pointer;
}

.top-nav-user-container:hover .menu::after {
  opacity: 1;
}
.top-nav-user-container.isopen .menu::after {
  opacity: 1;
  transform: rotate(180deg);
}
.top-nav-user-container .tooltip-container {
  width: 100%;
  padding: 5px;
  margin-top: 12px;
  animation: 0.3s ease tooltipUser;
  transition: 0.3s ease opacity;
}
@keyframes tooltipUser {
  from {
    opacity: 0;
    transform: translateY(65px);
  }
  to {
    opacity: 1;
    transform: translateY(55px);
  }
}

.top-nav-user-container__dropdown {
  width: 100% !important;
  overflow: hidden;
}
.top-nav-user-container__dropdown > li > button {
  padding: 12px !important;
  text-align: center !important;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #6a7998 !important;
  font-family: "tuibold";
  text-transform: uppercase;
}
.top-nav-user-container__dropdown > li > button:hover {
  color: #122a5b !important;
  background-color: #fff !important;
}

.mobi-tui-location-selector__location-prefix,
.tui-date-filter-value::before {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}
.mobi-tui-button.calendar .tui-date-filter-value::before {
  content: "Viewing ";
}
.mobi-tui-button.calendar .tui-date-filter-value svg {
  position: absolute;
  left: 15px;
}

.mobi-tui-button {
  height: 48px !important;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
.mobi-tui-button.calendar {
  padding-left: 43px;
}

.mobi-tui-filterbar .dropdown {
  border-top: 1px solid transparent;
  margin-top: -1px;
  margin-right: 0px !important;
  /*padding:0 10px;*/
  padding: 0 20px 0 0;
}
.mobi-tui-filterbar .dropdown.active {
  border-color: #8493ad;
}

.MuiChip-root {
  background-color: #d7dee8 !important;
  color: #122a5b !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 11px !important;
  position: relative;
}
.MuiChip-deleteIcon {
  border: 1px solid #fff;
  border-radius: 20px;
  color: #122a5b !important;
  margin: 0 !important;
  background-color: #fff;
  position: absolute;
  top: -5px;
  right: -5px;
}
.MuiChip-labelSmall {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.MuiChip-sizeSmall {
  margin: 2px;
  line-height: 23px;
  display: inline-block !important;
}
.chips {
  display: flex;
  flex-direction: column;
}
.chips > div {
  align-self: flex-start;
}
.MuiList-padding {
  padding: 0 !important;
}
.MuiListItem-root.MuiListItem-button {
  border-bottom: 1px solid #f1f1f1 !important;
}
.MuiListItem-gutters {
  padding-left: 5px !important;
}
.MuiPopover-root .MuiButtonBase-root .MuiSvgIcon-root {
  background-color: #dfdfdf;
  border-radius: 20px;
}
.MuiPopover-root .MuiButtonBase-root .MuiSvgIcon-root path {
  opacity: 0;
}
.MuiPopover-root .MuiListItem-root.Mui-selected .MuiSvgIcon-root {
  background-color: #fff;
}

.MuiPopover-root .MuiListItem-root.Mui-selected .MuiSvgIcon-root path {
  opacity: 1;
}

.MuiPopover-root .MuiListItem-root.Mui-selected {
  background-color: #fff !important;
}
.MuiPopover-root .MuiListItem-root.Mui-selected path {
  fill: var(--blue);
}

.MuiPopover-root .MuiTypography-body1 {
  font-family: "Open Sans", sans-serif !important;
  color: #122a5b !important;
  font-size: 13px !important;
}

.MuiPopover-root .MuiListItem-button:hover {
  background-color: #fff !important;
}

.MuiPopover-root .MuiListItem-root.Mui-selected .MuiTypography-body1 {
  font-weight: 600 !important;
}
.MuiPopover-root .MuiListItem-root::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 15px;
  width: 20px;
  height: 20px;
  background-image: url(/static/media/icon-filter-x.cf940168.svg);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.3s ease opacity, 0.3s ease transform;
  pointer-events: none;
  transform: translateY(10px);
  opacity: 0;
}
.MuiPopover-root .MuiListItem-root.Mui-selected::after {
  transform: none;
  opacity: 1;
}

.MuiPaper-elevation8 {
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08) !important;
}

.SearchBox_search-wrapper__3kXsZ {
  border-top: 1px solid transparent;
  margin-top: -1px;
}
.SearchBox_selected__27uZR {
  border-top: 1px solid #8493ad !important;
  margin-top: -1px;
  padding-left: 10px;
}
.SearchBox_search-input__20ECW {
  color: #122a5b !important;
}

.menu-app-container {
  display: block;
}

.menu-app-container .nav-menu {
  position: fixed;
  z-index: 10;
}
.menu-app-container .menu-wrapper {
  position: absolute;
  z-index: 10;
}
.menu-app-container .nav-menu {
  width: 200px;
  height: 100%;
  left: 0;
  top: 0;
  padding: 120px 0px 0px 20px;
  transform: translateX(-200px);
}
.menu-app-container.activemenu .nav-menu {
  transform: none;
}
.menu-app-container.activemenu .app-content {
  transform: translateX(200px);
}
.menu-app-container .menu-wrapper,
.menu-app-container .app-content {
  transition: 0.3s ease transform;
}
.menu-app-container.activemenu .menu-wrapper {
  transform: translateX(200px);
}

.mobi-tui-topnav > .search {
  background-image: none;
}
.sidebar {
  max-width: 320px;
}
.sidebar .title {
  white-space: normal !important;
  text-align: center;
}
/*
*/
/* text styles */
.t-label {
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.195em;
  text-transform: uppercase;
  color: #8493ad;
}
.t-title {
  font-size: 34px;
  letter-spacing: -0.005em;
  font-family: "tuibold";
}
.t-title.lg {
  font-size: 45px;
}
.t-title.sm {
  font-size: 28px;
}
.t-title.xs {
  font-size: 20px;
}
.t-title.xxs {
  font-size: 14px;
}
.t-copy {
  font-size: 18px;
  font-family: "tuilight";
}
.t-copy.heavy {
  font-weight: 700;
}
.t-copy.md {
  font-size: 16px;
}
.t-copy.sm {
  font-size: 14px;
}
.t-copy.xs {
  font-size: 12px;
}
.t-copy.light {
  color: #a3abb6;
}
.t-copy.dark {
  color: #071d3b;
}
.t-copy.midlight {
  color: #6a7889;
}

.t-copy.red {
  color: var(--red);
  font-size: 16px;
  font-family: "tuibold";
}
.t-compact {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

/* helpers */
.t-box-shadow {
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
}
.mb-xs {
  margin-bottom: 5px;
}
.mb-s {
  margin-bottom: 10px;
}
.mb-m {
  margin-bottom: 20px;
}
.mb-l {
  margin-bottom: 40px;
}
.mb-xl {
  margin-bottom: 80px;
}

.t-center {
  text-align: center;
}
.t-right {
  text-align: right;
}
.t-left {
  text-align: left;
}

.MuiToggleButton-label {
  font-size: 12px !important;
  line-height: 14px;
}

.card-placeholder .icon {
  border: 3px dashed var(--light-gray) !important;
  border-radius: 50px;
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  margin-right: 10px;
}
.card-placeholder .icon img {
  display: none;
}
.card-placeholder .t-label {
  color: #adadad !important;
}

.mobi-tui-filterbar {
  flex-wrap: wrap;
}
.mobi-tui-filterbar > .label {
  margin-right: 30px !important;
  padding-bottom: 20px;
}
.filter-container {
  margin-left: 0 !important;
  flex-grow: 1;
  border-top: 1px solid #d9e0ea;
  margin-top: -1px;
}
.filter-container .dropdown {
  flex-grow: 1;
  margin-bottom: 10px;
}
.search-bar {
  border-top: 1px solid #d9e0ea;
  margin-left: 0px !important;
  flex-grow: 1;
  margin-top: -1px;
}
@media (max-width: 1030px) {
  .mobi-tui-pagewrap {
    padding: 150px 40px 40px;
  }
  .sidebar-with-page__new-trip .mobi-tui-pagewrap {
    padding: 150px 40px 40px 360px;
  }
  .sidebar-with-page__new-trip .viewTripDetail .sidebar {
    transform: translateX(0);
  }
}

@media (max-width: 1030px) {
  .viewTripDetail .sidebar {
    transform: translateX(-320px);
  }

  .mobi-tui-topnav .logo {
    position: absolute;
    transform: translateX(-200px);
  }
  .mobi-tui-topnav .logo {
    display: none;
  }
  .mobi-tui-pageheader {
    flex-direction: column;
  }
  .mobi-tui-pageheader .title {
    text-align: center;
  }
  .mobi-tui-pageheader .actions {
    margin: 0px auto !important;
  }
  .mobi-tui-filterbar {
    flex-direction: column !important;
  }
  .filter-container {
    flex-direction: row;
    margin-left: 0px !important;
  }
  .filter-container .dropdown {
    padding: 0 !important;
    border-top: 1px solid #f1f1f1;
    flex-grow: 1;
  }
  .mobi-tui-filterbar .chips {
    margin-bottom: 10px;
  }
  .search-bar {
    /*        border-top:1px solid #f1f1f1;*/
    margin-left: 0px !important;
  }
  .SearchBox_search-wrapper__3kXsZ {
    padding-left: 0px !important;
  }
  .MuiSelect-selectMenu,
  .MuiSelect-select.MuiSelect-select {
    font-size: 13px !important;
  }

  .list-action {
    display: none !important;
  }

  @media (max-width: 800px) {
    .filter-container {
      flex-direction: column;
    }
    .filter-container {
      display: block !important;
    }
    .filter-container .dropdown {
      width: 50%;
      float: left;
    }
    .filter-container .dropdown:nth-child(2n + 1) {
      clear: left;
    }
  }

  .item-group.item-hotel {
    margin-left: 20px;
  }

  .t-textarea {
    padding-top: 4px;
    border-radius: 5px;
    font-family: "tuilight";
    font-size: 16px;
    line-height: 18px;
    padding: 9px 13px 13px 6px;
  }
  .t-textarea.bg_blue {
    background-color: var(--light-blue);
  }
  .t-tex-area.bg_white {
    background-color: var(--white);
  }

  .t-textarea.h_one-line {
    height: 34px;
    white-space: nowrap;
  }

  .t-textarea.h_multiline {
    white-space: normal;
    height: 70px;
  }
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.loading-spinner {
  z-index: 50;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: inherit
}
.loading-lightbox {
    padding-top: 20px;
    left:0;
    display: flex;
    justify-content: space-around;
    background-color: inherit;
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: none!important;
    z-index: inherit;
}

.tui-loading {
    z-index: inherit;
}
.tui-loading.MuiCircularProgress-colorPrimary {
    color: var(--blue);
}

.icon {
  position: relative;
}
.icon-header img {
  width: 16px;
}
.icon.noborder {
  border: none;
}
.icon.noborder img {
  border: 0px none;
}
.item.selected .icon::after {
  position: absolute;
  content: "";
  top: 0;
  left: 30px;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  background-color: #0065e8;
  border-radius: 20px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAASCAYAAACq26WdAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADVSURBVHgBtZRtEcMgDIYjYRIqoRImYQ42CTgYDloHxcEkTMIkIKES3sGN3nEbJGEHz13+UMiThg+iAQA4hbiH2FMsNIIkeuEXSz0JCacQHmV26oUg6icLiWZBFFmPHj+ywS2ONYjO6RBwuGPyVvgYN3hSiK6QWfMFtao8J8TnaEvY70VcC3xJqBSZUoVWWBSLmbP5C2RuxLRkVSQwKO9vtTBOKP2hhFeJOgg9FKe3JDRtHt014YQXyBc18kTDA8AJpSfIUU9Qf1wtjSAJXZLE1pp/c70Bb2hzTEyMho8AAAAASUVORK5CYII=);
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
}
.icon img {
  image-rendering: crisp-edges;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid #e0e6ef;
}

.icon-wrapper {
  color: var(--mid-cooler-gray);
  overflow: visible;
  width: 40px;
  height: 40px;
  border: 1px solid #e0e6ef;
  border-radius: 50px;
  background-color: #f5f9fe;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
}
.icon-wrapper img,
.icon-wrapper svg {
  width: 20px;
  height: 20px;
}

.icon-gray img{
    background-color: var(--status-gray);
    border-color: var(--status-gray);
}
.icon-green img{
    background-color: var(--status-green);
    border-color: var(--status-green);
}
.icon-yellow img{
    background-color: var(--status-yellow);
    border-color: var(--status-yellow);
}
.t-badge-container {
 position:relative;     
 width:min-content
}
.t-badge {
    position:absolute;
    top:-3px;
    right:-4px;
    min-width:10px;
    min-height:10px;
    line-height:10px;
    padding:5px;
    color:#fff;
    font-size:10px;
    border-radius:20px;
    content:'';
    font-size: 10px;
    border:solid 2px white
}  
.t-badge.red {
    background-color:var(--red);
}

.t-badge.blue {
    background-color: var(--blue);
}

.t-badge-hidden {
    display:none
}
.list-status {
  color: #d9935c;
  border: 1px solid #d9935c;
  border-radius: 36px;
  font-weight: bold;
  letter-spacing: -0.5px;
  font-size: 13px;
  margin: 0 5px;
  height: 26px !important;
  line-height: 24px !important;
  padding: 0 11px;
}
.gray {
  color: var(--status-gray);
  border-color: var(--status-gray);
}
.yellow {
  border-color: var(--status-yellow);
  color: var(--status-yellow);
}
.green {
  border-color: var(--status-green);
  color: var(--status-green);
}

.mobi-tui-button {
    display: flex;
    font-family: 'tuibold';
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: capitalize;
    padding-top: 9px;
    padding-bottom:10px;
    border-radius: 5px;
    width:100%;
    border-style: solid;
    box-sizing: border-box;
    border-width:1px;
    height: 36px;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.default {
    background: var(--blue);
    border-color:var(--blue);
    color: var(--blue);
}
.negative {
    background: var(--red);
    border-color:var(--red);
    color: var(--red);
}

.button.red {
    background: var(--red);
    border-color:var(--red);
    color: var(--red);
}
.light {
    background:var(--light-gray);
    border-color:var(--mid-gray);
    color:var(--mid-gray)!important;

}
.outline {
    background:transparent;
}

.fill {
    border:none !important;
    color: white;
}

.full-width {
    border-radius: 0px;
    width:100%
}

.btn-group-horiz .long {
    border-radius: 0px;
    width:60%
}
.btn-group-horiz .short {
    border-radius: 0px ;
    width:40%
}

.btn-group-horiz {
    display: flex;
    flex-direction: row;
}
.btn-small {
    height: 30px
}

.btn-large {
    height: 36px
}

:disabled {
    background: var(--lightest-gray);
    border-color:var(--mid-gray);
    color: var(--mid-gray);
}
.MuiSnackbar-root.t-notif-bar {
    font-family: tuilight;
    margin-top:50px;
}
.MuiSnackbar-root.t-notif-bar .MuiSnackbarContent-message {
    font-family: tuibold;
}
.mobi-tui-pageheader {
  display: flex;
  flex-wrap: wrap;
}

.mobi-tui-pageheader .filter-pills {
  flex-grow: 1;
}
.mobi-tui-pageheader .actions {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.mobi-tui-pageheader .actions > * + * {
  margin-left: 10px;
}

.t-table_wrapper {
  position: relative;
  background-color: inherit;
  width: 100%;
  min-height: 100px;
}

.t-table-wrapper .t-loading .MuiCircularProgress-root {
  color: var(blue);
}

.t-table_overlay-loading .t-loading {
  width: 30px;
}
.t-table_overlay-loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.t-table_no-bookings {
  position: absolute;
  height: 100%;
  padding-top: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  z-index: 2;
}
.t-table_no-bookings span {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.195em;
  text-transform: uppercase;
  color: #8493ad;
}

.mobi-tui-filterbar {
  border-top: 1px solid #d9e0ea;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

/* .mobi-tui-filterbar > div {
  position: relative;
  display: inline-block;
  padding: 10px 0;
  margin-right: 15px;
  font-weight: 600;
  margin-top: -1px;
} */
.mobi-tui-filterbar .label {
  color: #8493ad;
  width: max-content;
  white-space: nowrap;
  margin-top: 9px;
}
.mobi-tui-filterbar .dropdown {
  position: relative;
  margin-right: 15px;
}
.mobi-tui-filterbar .dropdown.active {
  border-top: 1px solid #8493ad;
}

.filter-container {
  display: flex;
  margin-left: 30px;
}
.search-bar {
  margin-left: auto;
}

.MuiInputBase-root {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-weight: 700 !important;
}

.MuiSelect-select:focus {
  background: none !important;
}
.MuiSelect-selectMenu,
.MuiSelect-select.MuiSelect-select {
  color: #122a5b;
  font-size: 14px;
  line-height: 16px;
  padding-top: 10px;
}

.MuiListItem-root.MuiListItem-button {
  border-bottom: 1px solid #dedede !important;
}

.MuiListItem-root.MuiListItem-button:last-child {
  /* background: yellow; */
  border: none !important;
}

/*
Search BOX
*/
.SearchBox_search-input__h2V0W {
  -webkit-appearance: none;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
  letter-spacing: 0.02em;
  color: #8493ad;
  background: none;
  /* font-family: TUITypeLight LATN; */
}
.SearchBox_search-wrapper__1minF {
  display: flex;
  padding-left: 10px;
  padding-right: 0px;
}
.SearchBox_selected__1Ob_a {
  border-top: 1px solid #8493ad;
}

.SearchBox_search-container__2IRxx {
  position: relative;
  display: flex;
  margin-top: 10px;
}
.SearchBox_btn-search__2DWkN {
  position: absolute;
  right: 0;
  top: 0;
}
.SearchBox_search-loader__GnoXX {
  margin-right: 10px;
  margin-top: 5px;
}

.SearchBox_clear-search-btn__BZYo2 {
  cursor: pointer;
  margin-right: 10px;
  border: none;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  font-size: 11px;
  font-weight: 700;
  color: #8493ad;
}
.SearchBox_clear-search-btn__BZYo2:focus {
  outline: none;
}

.SearchBox_hidden__e6YwQ {
  display: none;
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

body {
  background-color: #f7fbff;
}
.b-menu {
  cursor: pointer;
}
.page {
  margin-right: auto;
  width: 100%;
}

.tui-date-filter-value {
  white-space: nowrap;
}

.trips-view-footer {
  background-color: white;
  display: inline-block;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}

.trips-total-cost {
  color: black;
  font-weight: 600;
  font-size: 16px;
}

.b-pagebutton {
   border-radius:5px;
   padding:0 25px;
   position:relative;
   border:none
}
.b-pagebutton.editselected {
    padding-left:50px;

}
.b-pagebutton.editselected::before {
    position:absolute;
    content:'';
    top:50%;
    margin-top:-12px;
    left:16px;
    width:20px;
    height:20px;
    border-radius:20px;
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAASCAYAAACq26WdAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADVSURBVHgBtZRtEcMgDIYjYRIqoRImYQ42CTgYDloHxcEkTMIkIKES3sGN3nEbJGEHz13+UMiThg+iAQA4hbiH2FMsNIIkeuEXSz0JCacQHmV26oUg6icLiWZBFFmPHj+ywS2ONYjO6RBwuGPyVvgYN3hSiK6QWfMFtao8J8TnaEvY70VcC3xJqBSZUoVWWBSLmbP5C2RuxLRkVSQwKO9vtTBOKP2hhFeJOgg9FKe3JDRtHt014YQXyBc18kTDA8AJpSfIUU9Qf1wtjSAJXZLE1pp/c70Bb2hzTEyMho8AAAAASUVORK5CYII=);
    background-size:10px;
    background-position:center;
    background-repeat:no-repeat;
}
.tui-toggle.MuiToggleButtonGroup-root {
    color:var(--blue);
    border-color:var(--blue);
    font-family: 'tuibold';
    height:48px
}
.tui-toggle .MuiToggleButton-root {
    color:var(--blue);
    border-color:var(--blue);
    font-family: 'tuibold';
    line-height:16px;
    padding-top:10px;
    padding-bottom:9px
}

.tui-toggle .MuiToggleButton-root.Mui-selected {
    background-color: var(--blue);
    color: white;
}
.DayPickerNavigation_button {
  border: 1px solid var(--blue);
}

.DayPicker_transitionContainer {
  border-radius: 5px;
}

.DayPicker_weekHeader_ul {
  position: relative;
  left: 5px;
}

.CalendarMonth_table {
  margin-top: 10px;
}

.CalendarDay__default {
  border: 1px solid var(--blue);
}
.CalendarDay__default:hover {
  color: var(--blue);
}

.CalendarDay__selected_span {
  background: #86c8ff;
  color: white;
  border: 1px solid var(--blue);
}

.CalendarDay__selected {
  background: var(--blue);
  color: #fff;
}

.CalendarDay__selected:hover,
.CalendarDay__selected_span:hover {
  background: var(--blue);
  border: 1px solid var(--blue);
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #86c8ff;
  border: 1px solid var(--blue);
  color: white;
}

.tooltip-container {
  z-index: 5;
}

.mobi-tui-location-selector {
  --background-color: #fff;
  --text-color: #2a2c37;
  --carret-color: var(--blue);
  position: relative;
}

.mobi-tui-location-selector__carret {
  border-top: 0.5em solid var(--carret-color);
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  display: inline-block;
  position: relative;
  right: 0;
  top: 2px;
  pointer-events: none;
  z-index: 1;
}

.mobi-tui-location-selector__btn-content {
    display: flex;
    align-items: center;
}

.mobi-tui-location-selector__btn-content > * {
  white-space: nowrap;
}

.mobi-tui-location-selector__btn-content > * + *{
    margin-left: 10px;
}

.mobi-tui-location-selector__location-prefix {
    font-weight: 300;
}

.mobi-tui-location-selector__select-css {
  display: inline-block;
  font-size: 1em;
  padding: 0;
  flex: 1 1;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  background: transparent;
  font-weight: bold;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 0;
}

.mobi-tui-location-selector__select-css option {
  font-size: 12px
}
.select-css::-ms-expand {
  display: none;
}

.sidebar-with-page {
  z-index: 50;
  width: 100%;
  height: 100%;
}
.viewSidebar .trip-detail-container {
  transform: translateX(75vw);
}

.viewTripDetail .viewSidebar .trip-detail-container {
  transform: translateX(0px);
}

.trip-detail-container .sidebar {
  margin-top: 40px;
}

.trip-detail-container {
  transform: translateX(100vw);
  transition: 0.3s ease transform;
  display: flex;
  position: fixed;
  margin-top: 0px;
  flex-direction: row;
  align-content: flex-start;
  z-index: 50;
  min-height: 100vh;
  background-color: white;
  width: 100vw;
  justify-content: flex-start;
  overflow-y: scroll;
  box-shadow: 0px 0px 33px rgb(17 43 91 / 8%) !important;
}

.sidebar {
    z-index:50;
    min-width:320px;
    min-height:100vh;
    top:0;
    right:0;
    background-color:#fff;
    box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
    transition:0.3s ease transform;
    padding: 24px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.sidebar .info{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-between;
    padding-top:20px;
    margin-bottom:40px;
    min-height:105px;
}
.sidebar .info .icon {
    margin-bottom: 8px
}
.sidebar .card {
    margin: 10px 0px 0px 0px
}

.sidebar .settings {
    position: absolute;
    top: 20px;
    right: 20px;
}


.sidebar .label {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: rgba(18, 42, 91, 0.63);
}
.sidebar .title {
    line-height:40px;
    margin-bottom: 8px;
}
.alert {
  color: white;
  line-height: 18px;
  padding: 4px;
  margin-top: 4px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--red);
  align-self: flex-start;
}

.alert.outline {
  color: var(--red);
  border: 1px solid var(--red);
  background-color: transparent;
  box-sizing: border-box;
}

.alert.text-only {
  border: none;
  background-color: transparent;
  color: var(--red);
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0 0 0 0;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 80px;
}
.t-card .item {
  border-top: 0px;
}

.card-outline {
  background-color: transparent;
  box-shadow: none;
  border: 1px solid var(--lighter-gray);
}

.card-actions {
  width: 100%;
  align-self: flex-end;
}
.card-actions .mobi-tui-button {
  border-radius: 0;
}

.card_scrollable {
  overflow-y: scroll;
}

.card .item {
  width: 100%;
}
.t-card .item.card-content-horiz {
  flex-direction: row;
}
.item-info .t-title {
  white-space: nowrap;
}
.item-info .t-label {
  white-space: nowrap;
}

.t-card .item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.t-card .item .content {
  padding-left: 20px;
}

.t-card .item {
  border-top: 1px solid #f1f1f1;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  position: relative;
}
.t-card .item.selected {
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
}
.t-card .item:first-child {
  border-top: 0px;
}
.t-card .item.witharrow::after {
  position: absolute;
  content: "";
  top: 50%;
  margin-top: -9px;
  right: 20px;
  width: 18px;
  height: 18px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAA8CAYAAAAHbrgUAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALHSURBVHgBvZnNceJAEIVnpjjAzSFw4qeKA84AMlgiMBvB2hEsG4HJwDgCeyPAGcCNn5ND8I0DVbDvUVItdkmanlGPXxUlIbXUn1qjeZqRNdBms2lba5/wG57P5xtsesXyod/vv5vEskzunFtlif/vsPbjeDyOB4PB2iSUQ6LHr8kpbms0Gi8ENAnl8PtRsZ+3ZpkSghX48MRcIFar1Y1JAYBSS+5xu9VqJYEgwE9BFaghIF6MshwfNQCMhRCj3W73ZBTFRmg6nc76dDo9SA5Axab7/f7RKMnlK6jEgrdDchBg71GJ30ZB7vpPBvFHciDiZhoQtmgjnvsZ2oTo5IibdrvdZxMpW7Zju93OsfhlEkPYqp2oxAInvzN+gGjfsL4AVGKJxch7okgI5ws4HA4TLLwnjTUvbwUodsHoBVdYbQvC3wEzlr5LiACo7KVlKYVA5W4hb+/qvQW5eEW8Mp5cEC42LzFADoG3p4mmeQUBUPQNTfMKBsghtMwrCoDSMq9ogCuIWuYlfgyrVMe8VACoWPNSA6BizEsVgAoxLzTO21qNsEgh5nUZj5oEkpoXq6BeAarZbIoGMKyCOgCvHldGD2j7YtEG/qoDZAY09MWx/PjdqwJkxjPyxWXJLy8tagDsZmk8klgaGQ2N6yoAWfKZMJxTP4v8T22AkOQ0rl6vN7/eVqsfQK/Hvn8uiWVyXPns6/ZoAFz5HU66kMSWJY8GwBsOp/OWRZNbBcmfkXxatj8YoGxar0RvuOfjqoCgRpiPDYTJ15kxVSrZwEQ6OhIBpEpOeW9BiLmEJhcBhJhLaHIvQIy5mEC5iuRR5qICUMdcagPUNZdQfXoMNcwlGkDLXKIANM0lGEDbXEJV+s2oQCJzCQYw1d+McrGLnUhmvYIBBHM9wf17EIDnm1HS5DlA4TejWHMJBmACjtOR8DVPjMUbt33Hp9t/f6L2sHq6j/EAAAAASUVORK5CYII=);
  background-size: contain;
  background-repeat: no-repeat;
}
.t-card .item.witharrow.down::after {
  transform: rotate(90deg);
}
.t-card .item .icon {
  width: 65px;
  position: relative;
}

.t-card .item.selected .icon::after {
  position: absolute;
  content: "";
  top: 0;
  left: 30px;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  background-color: #0065e8;
  border-radius: 20px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAASCAYAAACq26WdAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADVSURBVHgBtZRtEcMgDIYjYRIqoRImYQ42CTgYDloHxcEkTMIkIKES3sGN3nEbJGEHz13+UMiThg+iAQA4hbiH2FMsNIIkeuEXSz0JCacQHmV26oUg6icLiWZBFFmPHj+ywS2ONYjO6RBwuGPyVvgYN3hSiK6QWfMFtao8J8TnaEvY70VcC3xJqBSZUoVWWBSLmbP5C2RuxLRkVSQwKO9vtTBOKP2hhFeJOgg9FKe3JDRtHt014YQXyBc18kTDA8AJpSfIUU9Qf1wtjSAJXZLE1pp/c70Bb2hzTEyMho8AAAAASUVORK5CYII=);
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
}
.t-card .item .icon img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid #e0e6ef;
}
.t-card .item .info {
  flex-grow: 1;
}
.t-card .item.witharrow .actions {
  padding-right: 40px;
  display: flex;
}
.t-card .item .actions {
  position: absolute;
  right: 20px;
  display: flex;
}
.item.card-content_input {
  align-items: flex-start;
}

.t-input-label {
    align-self: flex-start;
    color: var(--mid-dark-gray);
    padding-bottom:8px;
    font-size: 12px;
}

.t-radio .t-input-label {
    color: var(--dark-gray);
    font-size: 14px;
}
.sidebar-with-page.sidebar-with-page__new-trip .trip-detail-container {
  z-index: 60;
}

.t-radio-group {
  align-self: flex-start;
}
.t-radio-group label {
  display: flex;
}

.mobi-tui-rqjobstatus-selector {
  --background-color: #fff;
  --text-color: #2a2c37;
  --carret-color: var(--blue);
  position: relative;
}

.mobi-tui-rqjobstatus-selector__carret {
  border-top: 0.5em solid var(--carret-color);
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  display: inline-block;
  position: relative;
  right: 0;
  top: 2px;
  pointer-events: none;
  z-index: 1;
}

.mobi-tui-rqjobstatus-selector__btn-content {
    display: flex;
    align-items: center;
}

.mobi-tui-rqjobstatus-selector__btn-content > * {
  white-space: nowrap;
}

.mobi-tui-rqjobstatus-selector__btn-content > * + *{
    margin-left: 10px;
}

.mobi-tui-rqjobstatus-selector__location-prefix {
    font-weight: 300;
}

.mobi-tui-rqjobstatus-selector__select-css {
  display: inline-block;
  font-size: 1em;
  padding: 0;
  flex: 1 1;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  background: transparent;
  font-weight: bold;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 0;
}

.mobi-tui-rqjobstatus-selector__select-css option {
  font-size: 12px
}
.select-css::-ms-expand {
  display: none;
}

.card-passenger-list{
    max-height: 226px;
}
.t-card .card-data-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 16px;
}

.t-text-input {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.t-text-input .t-label {
  margin-left: 20px;
}
.t-text-input.collapse {
  height: 60px;
  transition: 0.3s ease-in-out height;
  overflow: auto;
}
.t-text-input.expand {
  height: 120px;
  transition: 0.3s ease-in-out height;
}
.t-text-input textarea {
  border-radius: 0px;
  border: none;
}
.t-text-input .button_text {
  background-color: white;
  border: none;
  outline: none;
  padding-left: 0px;
  width: min-content;
}
.t-text-input .text-display {
  box-sizing: -box;
  font-size: 16px;
  padding-top: 8px;
  padding-left: 20px;
  font-family: "tuilight";
}

.mobi-tui-topnav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 65px;
  z-index: 100;
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  z-index:1500
}

.mobi-tui-topnav > div {
  border-right: 1px solid #f1f1f1;
}
.mobi-tui-topnav .logo {
  width: 200px;
  height: 100%;
  margin-right: auto;
  border-right: none;
  background-image: url(/static/media/img-logoheader.6e321616.png);
  background-size: 130px;
  background-position: center;
  background-repeat: no-repeat;
}

.top-nav-user-container {
  height: 65px;
  display: flex;
  position: relative;
  align-items: center;
}

.top-nav-notif-container{
  height: 65px;
  display: flex;
  position: relative;
  align-items: center;
  border-left: 1px solid #f1f1f1;
}
.top-nav-user-container__dropdown {
  margin: 0;
  padding: 0;
  width: 150px;
  list-style: none;
  box-shadow: 0px 0px 33px rgba(17, 43, 91, 0.08);
  border-radius: 5px;
}

.top-nav-user-container__dropdown > li {
  background: #fff;
  width: 100%;
}

.top-nav-user-container__dropdown > li > button {
  -webkit-appearance: none;
          appearance: none;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
  padding: 5px;
  text-align: left;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;

  color: #122a5b;
}

.top-nav-user-container__dropdown > li > button:hover {
  background: #f1f1f1;
}

.nav-menu{
    background-color: white;
    z-index: 50;
    left:0;
    left:0;
    right:0;
    transition:0.3s ease all;
    padding-top:100px;
    display: flex;
    flex-direction: column;
}

.nav-menu.hide {
    /* margin-top:0 !important; */
    flex: 0 1
    /* transform:translateX(-100%) !important; */
}
.nav-menu.show {
    flex: 200px 0 1;
    padding-left:26px
    /* transform:translateX(0px)  !important; */
}
.nav-menu.hide a.menu-link{
    display:none
}
.menu-link {
    height: 12px;
    line-height: 11px;
    letter-spacing: 0.1em;
    border-top: 1px solid var(--lighter-gray);
    padding-top: 10px;
    padding-bottom:12px;
    cursor:pointer;
    text-decoration: none;
    margin-bottom:12px;
}

.menu-link.selected {
    border-color: var(--mid-gray)
}
.menu-link.selected a {
    color: var(--dark)
}

.menu-link a{
    color: var(--mid-gray);
    text-decoration: none;
}

.menu-link a:hover {
    color: var(--dark);
}
.b-menu {
    width:60px;
    height:60px;
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAkCAYAAADGrhlwAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEmSURBVHgB7dkxSgQxFAbg/80oOFbrwvZbioLiEbzBbqvNeIOdE4gnEG+QapkjWO4RxEIWq/QixMJ1WZw8MyJ2YhYCkwnvK8IUmeKFJOTnEZzh0cU1KJuBeYB+0gS+eX2aKxoeX96CMUMCrG3O82J0UrvvPSSAiJAhJYw3VxDdIRH2Eyr/eHlcFKPTA1fYIfq79bS1XJnn+T2EEEIIEQ61Q8fxQdnVbmW0Mghg5zc+MKMjJRWbdiGnCCBzxZTomHv2TwbjMsjuSCs+4LugKOKDCnWGOo4P1BZR29V7tTbLNYQQQggRlxjiw59cAFgwN1dmWWvff/rQfdAuL535vvWiiA//GOf7m4nv5F7Eh4bZ+yUefffBnSPthgff+VF3H34uhek2l8IXSh50z01Zzw8AAAAASUVORK5CYII=);
    background-size:20px;
    background-position:center;
    background-repeat:no-repeat;
    z-index:200;
}
.back-button {
    cursor: pointer
}
.bookings-view{
    display: flex;
    flex-direction: column;
}

.bookings-view .mobi-tui-button {
    margin:0px 5px 0px 5px
}
.task-card .task-card_no-bookings {
  border-top: none;
}
.task-card .t-table_wrapper {
  border-top: 1px s;
}
.task-card_header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 1px solid var(--lightest-gray);
  padding: 0px 8px;
  height: -moz-fit-content;
  height: fit-content;
}

.task-card_header .item.outer {
  flex-direction: row;
  padding: 14px 8px;
  flex-basis: 240px;
  border-top: none;
  height: 100%;
  align-items: flex-start;
}

.task-card_header .item.outer .item {
  padding: 0px 8px 0px 0px;
  border-top: none;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  white-space: wrap;
}
.task-card_header .item span {
  margin-right: 8px;
  display: block;
}

.task-card_header .item:first-child {
  flex-basis: 220px;
  flex-grow: 0;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-right: 12px;
}
.task-card_header > .item:last-child {
  flex-basis: 160px;
  border-left: 1px solid var(--lightest-gray);
  border-right: none;
  margin-left: 12px;
  margin-right: 10px;
}
.task-card_header .item:last-child .icon {
  margin-right: 8px;
}
.task-card_header .item:last-child .icon img {
  height: 40px;
  width: 40px;
}
.task-card_header .item.task-card_details {
  flex-direction: row;
}
.task-card .task-card_header .icon-wrapper {
  margin-right: 8px;
  height: 42px;
  width: 42px;
}

.tasks-view {
  width: 100%;
  background-color: white;
}

.task-card {
  margin-bottom: 40px;
}

.tasks-view .trip-detail-container {
  position: fixed;
}
.tasks-view .sidebar-with-page {
  padding-left: 0px !important;
}
.tasks-view .sidebar {
  padding-top: 0px !important;
  padding-left: 0px !important;
}
.tasks-view .mobi-tui-pagewrap {
  padding-top: 0px !important;
}

.map-container {
  height: 500px;
  width: 100%;
}
.settings {
    width:36px;
    height:36px;
    border-radius:36px;
    border:1px solid #D8D8D8;
    background-size:16px;
    background-position:center;
    background-repeat:no-repeat;
    opacity:0.7;
    transition:0.3s ease opacity;
    margin:0 5px;
}
.settings:hover{
    opacity:1;
    cursor:pointer;
}
.settings{
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANDSURBVHgBvZjPTxNBFMffTLctNYj14tX1LxD+AsrZgxL04AlIvBjF0sRE64XlhBqTtiHEi4lw8qCmXDy7/gX4H7hePVXFQFk643tLV7fT/TG7DftJBtrXNzPf+fXm7TKYgJc73boUwpIMqvSdSegB5+0nDxY3ISMMMrK1/b7GGP8c9puUYqG5dseGDHDICAN2K6bZGmQkVtDW665ptd5Wx+ytdyYwdjOyUQZ1z0eB2qI2IYbQJaPGWLFEy2FicdCpc+T+2i0b07OcG/MgxLq/b2Ia7kmA/QEbdNyTP06lVK0H6jnSPVloNu46kCRIEXOehIoaW7KcxBD+wCFS0FbLW18T8sN89ebTVYgS1Md1p7WH/HAOf//4CVGCrMZiT0jRgZxgIDpWY7UXKYjonxptyIkj93BXtY0JKpZcE3KCwohqM1RDQRbqoAndXQLkHkjx9czA8Tph86B9MHgN/9gjbQa/UAziRukgKegRUspO/7Rg0b4L2q1WtzplDCyM5IkDowMk3JO5YCzyBNFFqRuBfTHNtdvrcT7Ptz+0dUQBBUgp9vC/TRcye7HzcUNKsEAf59jlc+rMqNBMVYriG14fiQP0wdlpcJCwDumwk8R4gtAHZ3IP0rHB04yAkGLwRdvX3+y6/qglcz50XvDUVwXjs/quhXlIAWnhAwGp8l/O2HJY0qYy9KlBCoSEDn/2aKlNOTAWEuYkVaJ1rhRnNpL8KsUq+ZhJfhRcqe+zPHzJUgJj1+RFcaCz0bFiG7PITfVypJkhMRJE4un1Iv0px8C46ATaHQWDpIVPE4kzMMTBYvsnD/fMdexkRSe4Dtl9+nBpNWgwQpxsLLqCTCwrKGTFN8gUD1aUb6u2sPQjVeyYBEr+VduYoAslWIacKBszY/tsZIK9mxo3NeSUV1PcwYNxLXgwRmaofHa6TMgJOs3TF69cCtpGBA2PnwP54Ty+d+M7RAkipMsXIB9RzrAviBVEsxQU5UdSzIEuc8bnKKXQuf+CEZjqCgEN+D9Qr49gQPxXL65RejHQP4aemv9oRPTQDunQlKeg2rw/LkRLUKzYmIiuk+JGMUk+ZEf/JPchI5kFUUJOe8R7jTeEPuPTaCPr2zPiL179eZ3/p5rgAAAAAElFTkSuQmCC);
}
.sidebar .cards-passengers-tasks {
  margin-bottom: 8px;
}

.sidebar .info-tags {
  display: flex;
  flex-direction: row;
}

.sidebar .info-tags .list-status.infants-status {
  color: var(--mid-cooler-gray);
  border-color: var(--mid-cooler-gray);
  display: flex;
  flex-direction: row;
  height: 26px;
  line-height: 24px;
  line-height: 34px;
  padding-left: 6px;
  padding-right: 11px;
  padding-top: 0px;
  padding-bottom: 11px;
  border-radius: 36px;
  font-weight: bold;
  letter-spacing: -0.5px;
  font-size: 15px;
}
.sidebar .info-tags .infants-status .icon img {
  height: 26px;
  width: 26px;
}

.item.card-content_input .t-text-input {
  width: 100%;
}

/* .item.card-content-horiz .icon {
  width: 42px;
} */

.trip-detail-page {
    min-height:100%;
}
.header-notif-button.MuiButtonBase-root {
    padding: 20px 20px 18px 20px;
    width:60px;
    color: var(--light-gray)
}


.header-notif-button.MuiButtonBase-root .icon-header{
    margin: 6px;
}

.header-notif-button.MuiButtonBase-root .t-badge{
    padding:3px;
}
