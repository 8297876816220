.MuiInputBase-root {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-weight: 700 !important;
}

.MuiSelect-select:focus {
  background: none !important;
}
.MuiSelect-selectMenu,
.MuiSelect-select.MuiSelect-select {
  color: #122a5b;
  font-size: 14px;
  line-height: 16px;
  padding-top: 10px;
}

.MuiListItem-root.MuiListItem-button {
  border-bottom: 1px solid #dedede !important;
}

.MuiListItem-root.MuiListItem-button:last-child {
  /* background: yellow; */
  border: none !important;
}
