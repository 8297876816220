.mobi-tui-filterbar {
  border-top: 1px solid #d9e0ea;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

/* .mobi-tui-filterbar > div {
  position: relative;
  display: inline-block;
  padding: 10px 0;
  margin-right: 15px;
  font-weight: 600;
  margin-top: -1px;
} */
.mobi-tui-filterbar .label {
  color: #8493ad;
  width: max-content;
  white-space: nowrap;
  margin-top: 9px;
}
.mobi-tui-filterbar .dropdown {
  position: relative;
  margin-right: 15px;
}
.mobi-tui-filterbar .dropdown.active {
  border-top: 1px solid #8493ad;
}

.filter-container {
  display: flex;
  margin-left: 30px;
}
.search-bar {
  margin-left: auto;
}
