.sidebar .cards-passengers-tasks {
  margin-bottom: 8px;
}

.sidebar .info-tags {
  display: flex;
  flex-direction: row;
}

.sidebar .info-tags .list-status.infants-status {
  color: var(--mid-cooler-gray);
  border-color: var(--mid-cooler-gray);
  display: flex;
  flex-direction: row;
  height: 26px;
  line-height: 24px;
  line-height: 34px;
  padding-left: 6px;
  padding-right: 11px;
  padding-top: 0px;
  padding-bottom: 11px;
  border-radius: 36px;
  font-weight: bold;
  letter-spacing: -0.5px;
  font-size: 15px;
}
.sidebar .info-tags .infants-status .icon img {
  height: 26px;
  width: 26px;
}

.item.card-content_input .t-text-input {
  width: 100%;
}

/* .item.card-content-horiz .icon {
  width: 42px;
} */
