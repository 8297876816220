.sidebar-with-page {
  z-index: 50;
  width: 100%;
  height: 100%;
}
.viewSidebar .trip-detail-container {
  transform: translateX(75vw);
}

.viewTripDetail .viewSidebar .trip-detail-container {
  transform: translateX(0px);
}

.trip-detail-container .sidebar {
  margin-top: 40px;
}

.trip-detail-container {
  transform: translateX(100vw);
  transition: 0.3s ease transform;
  display: flex;
  position: fixed;
  margin-top: 0px;
  flex-direction: row;
  align-content: flex-start;
  z-index: 50;
  min-height: 100vh;
  background-color: white;
  width: 100vw;
  justify-content: flex-start;
  overflow-y: scroll;
  box-shadow: 0px 0px 33px rgb(17 43 91 / 8%) !important;
}
