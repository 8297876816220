.t-badge-container {
 position:relative;     
 width:min-content
}
.t-badge {
    position:absolute;
    top:-3px;
    right:-4px;
    min-width:10px;
    min-height:10px;
    line-height:10px;
    padding:5px;
    color:#fff;
    font-size:10px;
    border-radius:20px;
    content:'';
    font-size: 10px;
    border:solid 2px white
}  
.t-badge.red {
    background-color:var(--red);
}

.t-badge.blue {
    background-color: var(--blue);
}

.t-badge-hidden {
    display:none
}