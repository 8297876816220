.task-card .task-card_no-bookings {
  border-top: none;
}
.task-card .t-table_wrapper {
  border-top: 1px s;
}
.task-card_header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 1px solid var(--lightest-gray);
  padding: 0px 8px;
  height: fit-content;
}

.task-card_header .item.outer {
  flex-direction: row;
  padding: 14px 8px;
  flex-basis: 240px;
  border-top: none;
  height: 100%;
  align-items: flex-start;
}

.task-card_header .item.outer .item {
  padding: 0px 8px 0px 0px;
  border-top: none;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  white-space: wrap;
}
.task-card_header .item span {
  margin-right: 8px;
  display: block;
}

.task-card_header .item:first-child {
  flex-basis: 220px;
  flex-grow: 0;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-right: 12px;
}
.task-card_header > .item:last-child {
  flex-basis: 160px;
  border-left: 1px solid var(--lightest-gray);
  border-right: none;
  margin-left: 12px;
  margin-right: 10px;
}
.task-card_header .item:last-child .icon {
  margin-right: 8px;
}
.task-card_header .item:last-child .icon img {
  height: 40px;
  width: 40px;
}
.task-card_header .item.task-card_details {
  flex-direction: row;
}
.task-card .task-card_header .icon-wrapper {
  margin-right: 8px;
  height: 42px;
  width: 42px;
}
