.mobi-tui-pageheader {
  display: flex;
  flex-wrap: wrap;
}

.mobi-tui-pageheader .filter-pills {
  flex-grow: 1;
}
.mobi-tui-pageheader .actions {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.mobi-tui-pageheader .actions > * + * {
  margin-left: 10px;
}
